import { mascaraValor, toTitleCase, roundValor } from "@/app/global.js";

const CartMixin = {
  data() {
    return {
      PAYMENT_METHODS: {
        BOLETO: 1,
        AVISTA: 6,
        CHEQUE: 5,
        CREDIT_CARD: 2,
        DINHEIRO: 4,
        DEPOSITO_ANTECIPADO: 3,
      },
      APP_LIMITE_CREDITO_PEDIDO: {
        NAO_EXIBE: 1,
        EXIBE_BLOQUEIA: 2,
        EXIBE_NAO_BLOQUEIA: 3,
      },
    };
  },
  // created() {
  //   this.$store.dispatch("searchDiscountProducts");
  // },
  computed: {
    exibeLimiteCredito() {
      return (
        this.$store?.getters?.siteConfig?.app_limite_credito_pedido &&
        this.$store?.getters?.siteConfig?.app_limite_credito_pedido !=
          this.APP_LIMITE_CREDITO_PEDIDO.NAO_EXIBE
      );
    },
    bloqueiaPedidoPorLimiteCredito() {
      return (
        this.$store?.getters?.siteConfig?.app_limite_credito_pedido ==
        this.APP_LIMITE_CREDITO_PEDIDO.EXIBE_BLOQUEIA
      );
    },
    alertaLimiteCredito() {
      if (
        !this.exibeLimiteCredito ||
        this.paymentMethod !== this.PAYMENT_METHODS.BOLETO
      )
        return null;

      if (this.totalLiquido < this.dadosPedido?.informacoes?.limite_credito)
        return null;

      return this.bloqueiaPedidoPorLimiteCredito
        ? `O valor do pedido excedeu o seu limite de crédito de R$ ${this.mascaraValor(
            Number(this.dadosPedido?.informacoes?.limite_credito)
          )}. Tente usar outra forma de pagamento, ou entre em contato com a equipe financeira.`
        : `O valor do pedido excedeu o seu limite de crédito de R$ ${this.mascaraValor(
            Number(this.dadosPedido?.informacoes?.limite_credito)
          )} e será analisado pela equipe financeira.`;
    },
    isOrderParamsAvailable() {
      return (
        this.dadosPedido.status &&
        Object.values(this.dadosPedido.status).every((status) => status !== 0)
      );
    },
    clientSession() {
      if (
        !this.dadosPedido ||
        !this.dadosPedido.informacoes ||
        !this.dadosPedido.informacoes.document ||
        !this.dadosPedido ||
        !this.dadosPedido.pedidoId
      ) {
        return;
      }

      return `${this.dadosPedido.informacoes.document}${this.dadosPedido.pedidoId}`;
    },
    cardPaymentError: {
      get() {
        return this.$store.getters.cardPaymentError;
      },
      set(value) {
        this.$store.dispatch("cardPaymentError", value);
      },
    },
    isPaymentValid() {
      switch (this.paymentMethod) {
        case this.PAYMENT_METHODS.CREDIT_CARD:
          return this.validateCreditCardData();
          break;
      }

      return true;
    },
    deliveryAddress() {
      const { deliveryAddress } = this.dadosPedido;
      if (!deliveryAddress) return;

      const { street, number, neighborhood, city, state, zip, complement } =
        deliveryAddress;

      let fullAddress = "";

      if (street) {
        fullAddress += `${street}, `;
      }

      if (number) {
        fullAddress += `${number}, `;
      }

      if (neighborhood && state) {
        fullAddress += `${neighborhood} - ${state}, `;
      }

      if (zip) {
        fullAddress += `${zip}`;
      }

      return fullAddress;
    },
    isPaymentMethodValid() {
      return Object.values(this.PAYMENT_METHODS).includes(this.paymentMethod);
    },
    paymentMethod() {
      if (this.dadosPedido) {
        return this.dadosPedido.tipoPagamento;
      }

      return false;
    },
    paymentData() {
      return this.$store.getters.paymentData;
    },
    hasItemsInCart() {
      return this.cartProducts && this.cartProducts.length > 0;
    },
    hasPedidoAberto() {
      return Object.keys(this.$store.getters.dadosPedido).length !== 0;
    },
    dadosPedido() {
      return this.$store.getters.dadosPedido;
    },
    cartProducts() {
      const products = this.$store.getters.carrinhoAtual;
      return products.slice().sort((a, b) => b.lastUpdate - a.lastUpdate);
    },
    totalLiquido() {
      return (
        this.totalBruto +
        this.totalSt -
        this.totalDesconto -
        this.totalStDesonerado
      );
    },
    totalBruto() {
      return this.cartProducts.reduce((ac, item) => {
        if (item.valor > 0 && item.qtd > 0 && item.status !== 0)
          return ac + item.valor * item.qtd;
        return ac;
      }, 0);
    },
    totalDesconto() {
      return this.cartProducts.reduce((ac, item) => {
        if (item.vlrdesconto > 0 && item.qtd > 0 && item.status !== 0)
          return ac + roundValor(item.vlrdesconto, 2) * item.qtd;
        return ac;
      }, 0);
    },
    totalStDesonerado() {
      return this.cartProducts.reduce((ac, item) => {
        if (item.vlrstdesonerado > 0 && item.qtd > 0 && item.status !== 0)
          return ac + item.vlrstdesonerado * item.qtd;
        return ac;
      }, 0);
    },
    totalSt() {
      return this.cartProducts.reduce((ac, item) => {
        if (item.vlrst > 0 && item.qtd > 0 && item.status !== 0)
          return ac + item.vlrst * item.qtd;
        return ac;
      }, 0);
    },
  },
  methods: {
    mascaraValor,
    toTitleCase,
    validateCreditCardData() {
      if (!this.paymentData.card) return false;

      const { cardCvv, cardNumber, cardMonth, cardName, cardYear, card_id } =
        this.paymentData.card;

      if (
        (cardCvv && cardNumber && cardMonth && cardName && cardYear) ||
        (card_id && cardCvv)
      ) {
        return true;
      }

      return false;
    },
    async deleteCart(cartId) {
      if (!cartId) return;

      const response = await dpcAxios
        .connection()
        .get(
          process.env.VUE_APP_ECOMMERCE + api.env.EXCLUIR_PEDIDO_EC + cartId
        );
      if (response.data.error == 0) {
        if (this.dadosPedido?.pedidoId == cartId) {
          this.$store.dispatch("setDadosPedido", []);
          this.$store.dispatch("addItemCarrinhoAtual", "shouldCleanCart");
          localStorage.removeItem("pedidoaberto");
        }
      } else {
        throw new Error(response.data.message);
      }
    },
    async restoreCart(cartId, customerId) {
      const isAtCheckout =
        this.$route.name && this.$route.name.indexOf("checkout") >= 0;

      const response = await dpcAxios
        .connection()
        .post(
          `${
            process.env.VUE_APP_ECOMMERCE + api.env.EDICAO_PEDIDO_EC
          }ultimo-pedido`,
          {
            pedido_id: cartId,
            cliente_id: customerId,
            is_checkout: isAtCheckout, // == "checkoutReview",
          }
        );
      if (response.data.error == 0) {
        const {
          carrinho_compartilhado,
          permiteAlterarPermissaoFinalizarPedido,
          carrinhoNaoPodeSerCompartilhado,
          cliente,
          condpgto,
          empresa,
          formpgto,
          id,
          tabvnd,
          itens,
        } = response.data.pedido;
        const informacoesPedido = {
          condicaoPagamento: condpgto.id,
          formaPagamento: formpgto.id,
          tipoPagamento: formpgto.tipo,
          gatewayPagamento: formpgto.gateway_id,
          createdBySeller: formpgto.createdBySeller,
          pedidoId: id,
          tabvnd: tabvnd.id,
          unidade: empresa.id,
          razao: cliente.id,
          carrinho_compartilhado,
          permiteAlterarPermissaoFinalizarPedido,
          carrinhoNaoPodeSerCompartilhado,
          deliveryAddress: cliente.deliveryAddress,
          informacoes: {
            nomerazao: cliente.nomerazao,
            document: cliente.document,
            unidade: empresa.nomerazao,
            condicaoPagamento: condpgto.descricao,
            formaPagamento: formpgto.descricao,
            tabvnd: tabvnd.descricao,
            limite_credito: cliente.credit_limit,
          },
          status: {
            tabvnd: tabvnd.status,
            condicaoPagamento: condpgto.status,
            formaPagamento: formpgto.status,
          },
        };

        this.$store.dispatch("paymentData", {
          card: {
            cardParcels: condpgto.parcelas,
          },
        });

        this.$store.dispatch("setDadosPedido", informacoesPedido);
        localStorage.setItem("pedidoaberto", id);
        this.$store.dispatch("addItemCarrinhoAtual", "shouldCleanCart");

        itens.forEach((item) => {
          const itemProcessado = {
            integracao_id: item.produto.integracao_id,
            codigo_exibicao: item.produto.codigo_exibicao,
            cod_produto: item.produto.id,
            qtd: item.qtdpedida,
            descricao: item.produto.descricao,
            src: item.produto.src,
            pedido_id: id,
            vlrdesconto: item.vlrdesconto,
            vlrstdesonerado: item.vlrstdesonerado,
            valor: item.vlrproduto,
            vlrst: item.vlrst,
            status: item.produto.status,
            desconto_campanha: item.desconto_campanha,
            campanhasAtivas: item.campanhasAtivas,
            haspromocaocampanha: item.haspromocaocampanha,
            decim: item.decim,
            decim_num_casas: item.decim_num_casas,
            busca: 1, // definindo que há uma busca sendo feita através do carrinho (funcao editar)
          };
          this.$store.dispatch("addItemCarrinhoAtual", itemProcessado);
        });

        this.$store.dispatch("searchDiscountProducts");

        if (isAtCheckout) {
          window.dataLayer?.push({
            event: "begin_checkout",
            ecommerce: {
              items: [
                {
                  currency: "BRL",
                  value: roundValor(this.totalLiquido),
                  items: itens.map((item, index) => ({
                    item_id: item.produto?.integracao_id,
                    item_name: item.produto?.descricao,
                    discount: roundValor(
                      item.vlrdesconto + item.vlrstdesonerado
                    ),
                    index,
                    price: roundValor(
                      item.vlrproduto +
                        item.vlrst -
                        item.vlrdesconto -
                        item.vlrstdesonerado
                    ),
                    quantity: item.qtdpedida,
                  })),
                },
              ],
            },
          });
        }
      } else {
        localStorage.removeItem("pedidoaberto");
        this.$store.dispatch("setDadosPedido", []);
        this.$store.dispatch("addItemCarrinhoAtual", "shouldCleanCart");

        throw new Error(
          response.data.message || "Não foi possível restaurar o carrinho"
        );
      }
    },
  },
};

export default CartMixin;
