var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid",attrs:{"id":"section-parceiros"}},[_c('div',{staticClass:"slider-parceiros-body"},[_c('VueSlickCarousel',_vm._b({staticClass:"slider-parceiros"},'VueSlickCarousel',_vm.slideSettings,false),_vm._l((_vm.sliders),function(parceiro,index){return _c('div',{key:index,staticClass:"slider-parceiros-wrapper"},[_c('div',{staticClass:"slider-parceiros-item"},[_c('router-link',{attrs:{"tag":"a","to":{
              name: 'catalogoRota',
              query: {
                busca: _vm.$route.query.busca,
                categoria: _vm.$route.query.categoria,
                fornecedor: parceiro.link,
                sub: _vm.$route.query.sub,
                ordem: _vm.$route.query.ordem,
                visualizacao: _vm.$route.query.visualizacao,
              },
            }}},[_c('ImageItem',{attrs:{"custom-class":"img-responsive","description":parceiro.titulo,"source":parceiro.src}})],1)],1)])}),0)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }