import { render, staticRenderFns } from "./ParceirosSlider.vue?vue&type=template&id=3fbeb834&scoped=true"
import script from "./ParceirosSlider.vue?vue&type=script&lang=js"
export * from "./ParceirosSlider.vue?vue&type=script&lang=js"
import style0 from "./ParceirosSlider.vue?vue&type=style&index=0&id=3fbeb834&prod&lang=scss"
import style1 from "./ParceirosSlider.vue?vue&type=style&index=1&id=3fbeb834&prod&lang=scss&scoped=true"
import style2 from "./ParceirosSlider.vue?vue&type=style&index=2&id=3fbeb834&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fbeb834",
  null
  
)

export default component.exports