<template>
  <div>
    <!-- <ToolbarCallCenter></ToolbarCallCenter> -->
    <footer class="footer">
      <div class="container info-gerais">
        <section class="subContainerSec">
          <div class="col-sm-12">
            <div class="col-xs-12 col-md-4 col-sm-6">
              <section>
                <div class="img-logo-box">
                  <img
                    v-if="logoUrl"
                    loading="lazy"
                    :src="logoUrl"
                    class="img-logo"
                  />
                  <img
                    v-else
                    loading="lazy"
                    src="~@/assets/img/no-logo.svg"
                    class="img-logo"
                  />

                  <!-- <h5><strong>Quem Somos</strong></h5> -->
                  <p class="footer-text">
                    Levando o que há de melhor até você. <br />
                    Desde 2003 atuando no mercado da distribuição no estado do
                    Espírito Santo e Rio de Janeiro, construindo uma empresa
                    forte e sólida baseada em parcerias com a indústria e pelo
                    comprometimento com os clientes.
                  </p>
                </div>
                <section class="section-social">
                  <h5>
                    <strong> Redes sociais</strong>
                  </h5>
                  <a target="_blank" rel="noopener" :href="social.instagram">
                    <i
                      class="fa-brands fa-instagram social-icon fa-2"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <a target="_blank" rel="noopener" :href="social.youtube">
                    <i
                      class="fa-brands fa-youtube social-icon fa-2"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <a target="_blank" rel="noopener" :href="social.facebook">
                    <i
                      class="fa fa-facebook social-icon fa-2"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <a target="_blank" rel="noopener" :href="social.linkedin">
                    <i
                      class="fa-brands fa-linkedin social-icon fa-2"
                      aria-hidden="true"
                    ></i>
                  </a>
                </section>
              </section>
            </div>
            <div class="col-xs-12 col-md-4 col-sm-3">
              <section>
                <h5>
                  <strong>A Unilider</strong>
                </h5>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="https://unilider.com.br/institucional"
                    >
                      <span>Institucional</span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://unilider.com.br/sobre">
                      <span>Sobre nós</span>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://unilider.com.br/politica-de-privacidade"
                    >
                      <span>Política de Privacidade</span>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://unilider.com.br/vagas">
                      <span>Trabalhe conosco</span>
                    </a>
                  </li>

                  <li>
                    <a target="_blank" href="https://unilider.com.br/contato">
                      <span>Fale conosco</span>
                    </a>
                  </li>
                </ul>
              </section>

              <section>
                <h5>
                  <strong>SAC / Vendas internas</strong>
                </h5>
                <ul>
                  <li>
                    <a href="tel://27998247116">(27) 99824-7116</a>
                  </li>
                </ul>

                <div class="text-block" style="padding-top: 15px">
                  <div>
                    <strong>Horário de funcionamento:</strong>
                    <br />Segunda a sexta-feira
                    <br />
                    08:00 às 18:00
                  </div>
                </div>
                <br />
              </section>
            </div>
            <div class="col-xs-12 col-md-4 col-sm-3">
              <section>
                <h5>
                  <strong>Minha conta</strong>
                </h5>
                <ul>
                  <li>
                    <router-link to="/cadastro" tag="a">
                      <span>Criar conta</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/minha-conta/pedidos" tag="a">
                      <span>Meus pedidos</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/minha-conta/cadastro" tag="a">
                      <span>Meus dados</span>
                    </router-link>
                  </li>
                </ul>

                <!-- <h5>
                  <strong>Métodos de pagamento</strong>
                </h5>
                <ul>
                  <li>
                    <img
                      src="~@/assets/img/payment-methods.png"
                      alt=""
                      width="185"
                      loading="lazy"
                    />
                  </li>
                </ul> -->
              </section>

              <section>
                <h5>
                  <strong>Ajuda</strong>
                </h5>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="https://unilider.com.br/duvidas-frequentes"
                    >
                      <span>Dúvidas frequentes</span>
                    </a>
                  </li>
                </ul>
              </section>
            </div>
          </div>

          <div
            class="col-sm-12 col-md-2 logo"
            style="border-top: 1px solid #ffffff1f; margin-top: 40px"
          ></div>
          <!-- <div
            class="col-sm-12 col-md-2 logo"
            style="border-top: 1px solid #ffffff1f; margin-top: 40px"
          >
            <section class="logo-section">
              <section>
                <h5>
                  <strong>SAC / Vendas internas</strong>
                </h5>
                <ul>
      
                  <li>

                    <a href="tel://27998247116">(27) 99824-7116</a>

                  </li>
                </ul>

                <div class="text-block" style="padding-top: 15px">
                  <div>
                    <strong>Horário de funcionamento:</strong>
                    <br />Segunda a sexta-feira
                    <br />
                    08:00 às 18:00
                  </div>
                </div>
                <br />
              </section>

              <section>
                <h5>
                  <strong>Ajuda</strong>
                </h5>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="https://unilider.com.br/duvidas-frequentes"
                    >
                      <span>Dúvidas frequentes</span>
                    </a>
                  </li>
                </ul>
              </section>


            </section>
          </div> -->
        </section>
      </div>
      <div class="container footer-bottom">
        <div class="container">
          <div class="row">
            <div class="col-md-12 dados-empresa">
              <p class="text-center">
                © Copyright {{ appNameShort }} - Todos os direitos reservados.
              </p>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-12 dados-empresa">
              <p class="text-center">
                Desenvolvido por:
                <a
                  :href="`https://aglets.com.br?utm_source=${siteHost}`"
                  target="_blank"
                  ><img
                    src="https://i.imgur.com/5UUfvpe.png"
                    style="height: 17px"
                /></a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import * as global from "@/app/global";
import ToolbarCallCenter from "@/components/toolbar-callcenter/ToolbarCallCenter.vue";

export default {
  name: "Footer",
  components: {
    ToolbarCallCenter,
  },
  data() {
    return {
      social: {
        instagram: "https://www.instagram.com/unilider_oficial/",
        facebook: "https://www.facebook.com/DistribuidoraUnilider/",
        linkedin: "https://www.linkedin.com/company/unilider-distribuidora-sa",
        youtube: "https://www.youtube.com/@UniliderDistribuidoraOficial",
      },
      logoUrl: this.$store.getters.siteConfig.app_logo,
      appNameShort: this.$store.getters.siteConfig.app_name_short,
      showTrabalheConosco:
        process.env.VUE_APP_STORE_SHOW_TABALHE_CONOSCO == "true",
    };
  },
  computed: {
    siteHost() {
      return window?.location?.host;
    },
    getTelTelevenda() {
      return global.mascaraTelefone(
        this.$store.getters.informacaoGlobal.tel_televenda
      );
    },
    getTelSac() {
      return global.mascaraTelefone(
        this.$store.getters.informacaoGlobal.tel_sac
      );
    },
    getFaleConosco() {
      return global.mascaraTelefone(
        this.$store.getters.informacaoGlobal.fale_conosco
      );
    },
    getTelHrDiaUtilInicio() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.tel_hr_dia_util_inicio
      );
    },
    getTelHrDiaUtilFim() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.tel_hr_dia_util_fim
      );
    },
    getTelSabadoInicio() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.tel_hr_sabado_inicio
      );
    },
    getTelSabadoFim() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.tel_hr_sabado_fim
      );
    },
    getTelDomingoInicio() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.tel_hr_domingo_inicio
      );
    },
    getTelDomingoFim() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.tel_hr_domingo_fim
      );
    },
    getSacHrDiaUtilInicio() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.sac_hr_dia_util_inicio
      );
    },
    getSacHrDiaUtilFim() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.sac_hr_dia_util_fim
      );
    },
    getSacSabadoInicio() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.sac_hr_sabado_inicio
      );
    },
    getSacSabadoFim() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.sac_hr_sabado_fim
      );
    },
    getSacDomingoInicio() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.sac_hr_domingo_inicio
      );
    },
    getSacDomingoFim() {
      return global.mascaraHoraMinuto(
        this.$store.getters.informacaoGlobal.sac_hr_domingo_fim
      );
    },
  },
  mounted() {
    const script = document.createElement("script");
    // script.onload = () => {
    // }
    script.src = "//cdn.siteblindado.com/aw.js";
    document.head.appendChild(script);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/sass/bootstrap/variables";
.img-logo-box {
  justify-self: flex-start;
  width: 60%;
}
.right-side {
  width: 32%;
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 5px;
}
// .section-social {
//   width: 25%;
// }
.subContainerSec {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.logo-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.sb-seal {
  background-color: white;
  border-radius: 6.5px;
  padding: 5px;
  width: fit-content;
}
.footer {
  bottom: 0;
  width: 100%;
  padding-top: 35px;
  background-color: var(--brand-secondary, $brand-secondary);
  color: #fff;
  font-size: 0.95em;
  h5 {
    font-weight: 600;
    color: var(--brand-accent, $brand-accent);
    font-size: 1.1em;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .social-icon {
    background-color: var(--brand-primary, $brand-primary);
    border: 1px solid var(--brand-primary, $brand-primary);
    color: white;
    padding: 0;
    border-radius: 50px;
    font-size: 1.15em;
    width: 25px;
    height: 25px;
    text-align: center;
    vertical-align: middle;
    line-height: 25px;
    margin: 0;

    &:hover {
      opacity: 0.8;
      color: var(--brand-accent, $brand-accent);
      // color: #fff;
      // border: 01px solid #fff;
    }
    &:before {
      top: 2px;
      position: relative;
    }
  }
}
.footer-bottom {
  padding-top: 30px;
  padding-bottom: 10px;
  font-size: 1em;
}
.col-sm-12.logo {
  text-align: center;
  padding-top: 25px;
}
.logo {
  display: flex;
  width: 100%;
}
.img-logo {
  width: 148px;
  margin-left: -10px;

  display: flex;
}
.footer-text {
  padding-top: 15px;
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 0.97em;
}
.fone {
  font-size: 20px;
  margin: 0px;
  font-weight: bold;
  margin-bottom: 3px;
  font-size: 1.3em !important;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    a {
      color: #fff;

      &:hover {
        color: var(--brand-accent, $brand-accent);
      }

      span {
        font-weight: 500;
        line-height: 28px;
      }
    }
  }
  .fa-angle-right {
    //font-size: 10px;
    margin-right: 4px;
    color: $brand-info;
  }
}
.dados-empresa {
  padding-left: 0;
}
@media (max-width: 768px) {
  .right-side {
    width: initial !important;
    margin-bottom: 25px;
  }
  .logo-section {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .section-social {
    width: 100%;
    margin-top: 10px;
    justify-items: center;
  }
  .img-logo-box {
    width: 50%;
  }
  .footer {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .img-logo-box {
    width: 100%;
    place-items: center;
  }

  .subContainerSec,
  .logo-section {
    text-align: center;
    align-items: center;
  }

  // .logo-section-sac-cc {
  //   display: flex;
  //   flex-direction: row-reverse;
  //   width: 100%;
  //   align-content: space-between;
  //   gap: 35px;
  //   flex-wrap: wrap;
  //   justify-content: center;
  // }

  .right-side {
    align-items: center;

    div:first-child {
      flex-direction: row !important;
      align-items: center;
      gap: 15px;
    }
  }
}

@media (max-width: 991px) {
  .footer {
    padding-top: 20px;
  }
  .footer-bottom {
    border: none;
    padding-top: 0px;
    p {
      text-align: center;
    }
  }
}
</style>
